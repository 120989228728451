import { Avatar, LinearProgress, Link, Stack, Button } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import { useTheme } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import { isEmpty } from 'lodash';
import posthog from 'posthog-js';
import { useCallback, useMemo } from 'react';
import { _filters } from 'src/_mock';
import Logo from 'src/components/logo';
import { useOffSetTop } from 'src/hooks/use-off-set-top';
import { useResponsive } from 'src/hooks/use-responsive';
import { useRouter } from 'src/routes/hooks';
import FiltersBar from 'src/sections/home/filters-bar';
import { bgBlur } from 'src/theme/css';

import { HeaderShadow } from '../_common';
import { useAuthContext } from '../../auth/hooks';
import { HEADER } from '../config-layout';

type Props = {
  showProgressBar?: boolean;
  currentProgress?: number;
  title?: string;
  withLogo?: boolean;
  hideOption?: boolean;
  withProfile?: boolean;
};

export default function Header({
  showProgressBar,
  currentProgress,
  title,
  withLogo,
  hideOption,
  withProfile,
}: Props) {
  const theme = useTheme();
  const offsetTop = useOffSetTop(HEADER.H_DESKTOP_OFFSET);
  const router = useRouter();
  const { user } = useAuthContext();
  const mdUp = useResponsive('up', 'md');

  const currentUserPhoto = useMemo(() => {
    if (isEmpty(user?.avatar)) {
      return '';
    }
    return user?.avatar?.picture?.url;
  }, [user]);

  const handleTicket = useCallback(() => {
    if (!user) {
      router.push('/splash');
    } else {
      router.push('/me/events/?type=ticket');
    }
  }, [router, user]);

  return (
    <AppBar>
      <Toolbar
        disableGutters
        sx={{
          height: {
            xs: HEADER.H_MOBILE,
            md: hideOption ? 50 : HEADER.H_DESKTOP,
          },
          ...(offsetTop && {
            ...bgBlur({
              blur: 8,
              color: theme.palette.background.default,
            }),
          }),
          ...(mdUp &&
            !hideOption && {
              borderBottom: '2px solid #000',
            }),
          maxWidth: '100vw',
        }}
      >
        <Stack
          direction="column"
          sx={{
            px: { md: 4, xs: 1 },
            width: '100%',
            gap: 2,
          }}
        >
          {showProgressBar && (
            <LinearProgress
              value={currentProgress}
              variant="determinate"
              sx={{ mb: 1.5, width: 1 }}
            />
          )}
          <Stack
            flexDirection="row"
            alignItems="center"
            justifyContent={!withLogo && !withProfile && title ? 'center' : 'space-between'}
          >
            {withLogo && <Logo sx={{ mx: { sx: 1, md: 0 } }} />}

            <Stack direction="row" gap={3}>
              {mdUp && (
                <>
                  <Button href="/" variant="text">
                    Explore
                  </Button>
                  {/* <LanguagePopover /> */}
                  {/* <Button href="/connects" variant="text">
                    Connect
                  </Button>
                  
                  <Button href="/messages" variant="text">
                    Messages
                  </Button> */}
                  <Button onClick={() => handleTicket()} variant="text">
                    Tickets
                  </Button>
                  {!user && (
                    <Button onClick={() => router.push('/splash')} variant="outlined">
                      Login
                    </Button>
                  )}
                </>
              )}
              {user && withProfile && (
                <Link href="/settings" onClick={() => posthog.capture('navbar_settings_clicked')}>
                  <Avatar
                    src={currentUserPhoto ?? ''}
                    sx={{ width: 35, height: 35, mx: 1 }}
                    variant="rounded"
                  />
                </Link>
              )}
            </Stack>
          </Stack>
          {mdUp && !hideOption && <FiltersBar filters={_filters} />}
        </Stack>
      </Toolbar>
      {offsetTop && <HeaderShadow />}
    </AppBar>
  );
}
